import Vue from "vue";
import Router from "vue-router";
import Home from "./components/Home.vue";
import StationDetail from "./components/StationDetail.vue";
import { RouterBase } from "../CharginStations/RouterBase";

Vue.use(Router);

const baseRouter = new RouterBase(Home, StationDetail);

export default new Router({
  mode: baseRouter.mode,
  base: "/",
  routes: baseRouter.routes,
});
