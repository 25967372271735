import Component from "vue-class-component";
import router from "./router";
import { AppBase } from "./../CharginStations/AppBase";

@Component({
  router,
})
export default class App extends AppBase {
  protected sourceUrl = "https://fdrive.cz/data/export/pub/charging-stations.json";
  protected pinColors = {
    fast: "nabijame_blue",
    regular: "nabijame_green",
    selected: "nabijame_dark",
    wip: "nabijame_grey"
  };
  protected allowGeoAccessMessage = "Povoľte prosím prístup k polohovým službám vo Vašom prehliadači pre plnú funkčnosť stránky.";

  protected mounted() {
    this.mounted();
  }

  protected centerCurrentLocation(event: MouseEvent) {
    super.centerCurrentLocation(event);
  }

  protected fitCountryInView() {
    super.fitCountryInView();
  }
}
