import Vue from 'vue';
import App from './App.vue';

Vue.component('app', App);
new Vue({
    el: '#chargin-stations'
});

Vue.filter('nl2br', (value: string) => {
    if (typeof value === 'undefined' || value === null) {
        return '';
    }
    return (value + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + '<br>' + '$2');
});