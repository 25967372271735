import { Component } from "vue-property-decorator";
import Partners from "./Partners.vue";
import HomeBase from "../../CharginStations/components/Home";
import { PaymentMethodModel, StationModel } from "../../CharginStations/Station";
import App from "../../CharginStations/App";

@Component({
  components: {
    Partners,
  },
})
export default class Home extends HomeBase {
  protected documentTitle = "Oficiálna mapa nabíjacích staníc na Slovensku";
  protected documentDescr = "Nabijame.sk je najväčšia slovenská mapa nabíjacích staníc s podrobnými parametrami a napojením na najväčších poskytovateľov nabíjania na Slovensku a v zahraničí.";

  get paymentMethodsSorted() {
    const methods: Array<PaymentMethodModel> =
        (<App>this.$parent).$data.payment_methods;

    if (Object.keys(methods).length) {
      return Object.entries(methods)
          .filter(([id, method]) =>
              ((<App>this.$parent).$data.stations as Array<StationModel>).find((station) =>
                  station.payment_methods?.find(
                      (_method) => _method.toString() === id
                  )
              )
          )
          .sort(([a_id, a_method], [b_id, b_method]) =>
              a_method.order < b_method.order
                  ? -1
                  : a_method.order > b_method.order
                      ? 1
                      : 0
          )
          .map((method) => {
            switch (method[1].name) {
              case "Platební karta":
                method[1].name = "Platobná karta";
                break;
              case "Tarif poskytovatele":
                method[1].name = "Tarifa poskytovateľa";
                break;
              case "Hotovost":
                method[1].name = "Hotovosť";
                break;
              case "Zdarma":
                method[1].name = "Zadarmo";
                break;
              case "Ostatní":
                method[1].name = "Ostatné";
                break;
            }
            return method;
          });
    } else {
      return [];
    }
  }
}
