<template>
  <div class="charging-map__panel__main__partners">
    <div class="title">Zlatý partner</div>
    <div class="logos">
      <a href="https://zsedrive.sk/" target="_blank" rel="noopener">
        <img
          src="https://cdn.24net.cz/resources/5/img/nabijame-sk/zse-drive-logo.svg"
          width="190"
          height="30"
          alt="ZSE Drive logo"
        />
      </a>
    </div>
    <div class="title">Partner</div>
    <div class="logos">
      <a
        href="https://www.mojelektromobil.sk/"
        target="_blank"
        rel="noopener"
        class="partner-logo"
      >
        <img
          src="https://cdn.24net.cz/resources/5/img/nabijame-sk/moj-elektromobil-logo.svg"
          width="103"
          height="60"
          alt="MojElektromobil.sk logo"
        />
      </a>
    </div>
    <slot></slot>
  </div>
</template>

<style lang="scss">
.charging-map__panel__main__partners a {
  display: block;
  margin-bottom: 17px;
}
</style>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";

@Component({})
export default class Partners extends Vue {}
</script>
