import { Component } from "vue-property-decorator";
import StationDetailBase from "../../CharginStations/components/StationDetail";

@Component({
    components: {
        'flickity': () => import(/* webpackChunkName: "Flickity" */ "../../Flickity/Flickity.vue")
    },
    data() {
        return {
            station: undefined
        };
    }
})
export default class StationDetail extends StationDetailBase {
    protected get documentTitle(): string {
        return  `${this.station?.name} – Kde Nabít?`;
    }
}